import './SnakePlayground.css';
import React from 'react';
import * as FontFaceObserver from 'fontfaceobserver';

import '../snake-core/main'; // let the game begin
import { spawnCanvas, score$, maxScore$, renderInitialScene } from '../snake-core';

interface Props {
  onScoreChanged: (score: number) => void;
  onMaxScoreChanged: (maxScore: number) => void;
};

class SnakePlayground extends React.Component<Props> {

  containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    spawnCanvas(this.containerRef.current);
    new FontFaceObserver('Raleway').load().then(e => renderInitialScene()).catch(error => console.error(error));

    score$.subscribe(this.props.onScoreChanged);
    maxScore$.subscribe(this.props.onMaxScoreChanged);
  }

  render() {
    return (
      <div ref={this.containerRef} className="snake-playground" />
    );
  }
}

export default SnakePlayground;