import './GameStats.css';
import React from 'react';

interface Props {
  score: number;
  maxScore: number;
};

const GameStats = (props: Props) => {
  return (
    <div className="game-stats">
      <span>
        <strong>Score:</strong> {props.score}
      </span>
      <span>
        <strong>Max score:</strong> {props.maxScore}
      </span>
    </div>
  );
};

export default GameStats;