import { Directions, Point } from './utils';

export const DIRECTIONS: Directions = {
  UP: { x: 0, y: -1 },
  DOWN: { x: 0, y: 1 },
  LEFT: { x: -1, y: 0 },
  RIGHT: { x: 1, y: 0 },
};

export function mapControls(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowUp':
    case 'w': {
      return DIRECTIONS.UP;
    }
    case 'ArrowDown':
    case 's': {
      return DIRECTIONS.DOWN;
    }
    case 'ArrowLeft':
    case 'a': {
      return DIRECTIONS.LEFT;
    }
    case 'ArrowRight':
    case 'd': {
      return DIRECTIONS.RIGHT;
    }
    default: {
      return null;
    }
  }
}

export function checkOppositeDirection(prev: Point, next: Point) {
  if (prev.x === next.x * -1 || prev.y === next.y * -1) {
    return prev;
  }

  return next;
}
