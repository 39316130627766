import { Point, checkCollision, RegenerateEntities } from './utils';
import { getCenterPosition, COLS, ROWS } from './canvas';

export const SNAKE_LENGTH = 5;

export function generateSnake(): Point[] {
  const snake = [];

  for (let i = 0; i < SNAKE_LENGTH; i++) {
    snake.push(getCenterPosition());
  }

  return snake;
}

export function getSnakeHead(snake: Point[]) {
  return snake[0];
}

export function moveSnake(prevSnake: Point[], [direction, snakeLength, regEntities]: [Point, number, RegenerateEntities]) {
  if (regEntities.snake) {
    prevSnake = generateSnake();
  }

  const head = getSnakeHead(prevSnake);
  let bodyPart: Point;

  if (snakeLength > prevSnake.length) {
    bodyPart = {
      x: head.x + direction.x,
      y: head.y + direction.y,
    };
  } else {
    bodyPart = prevSnake.pop();
    bodyPart.x = head.x + direction.x;
    bodyPart.y = head.y + direction.y;
  }

  if (bodyPart.x === COLS) {
    bodyPart.x = 0;
  }

  if (bodyPart.x < 0) {
    bodyPart.x = COLS;
  }

  if (bodyPart.y === ROWS) {
    bodyPart.y = 0;
  }

  if (bodyPart.y < 0) {
    bodyPart.y = ROWS;
  }

  prevSnake.unshift(bodyPart);

  return prevSnake;
}

export function bodyHit(snake: Point[]) {
  const head = getSnakeHead(snake);
  const body = snake.slice(1, snake.length);

  return body.some(bodyPart => checkCollision(bodyPart, head));
}
