import { DrawRectConfig, DrawTextConfig, Scene, Point, getRandomNumber } from './utils';

export const canvas = document.createElement('canvas');
export const canvasContext = canvas.getContext('2d');

export let COLS = 30;
export let ROWS = 40;

export const CELL_WIDTH = 10;

export let canvasWidth = COLS * CELL_WIDTH;
export let canvasHeight = ROWS * CELL_WIDTH;

export function spawnCanvas(targetElement: HTMLElement = document.body) {
  const { width, height } = targetElement.getBoundingClientRect();

  COLS = Math.trunc(width / CELL_WIDTH) - 2;
  ROWS = Math.trunc(height / CELL_WIDTH) - 2;

  if (COLS > 60) {
    COLS = 60;
  }

  if (ROWS > 60) {
    ROWS = 60;
  }

  canvasWidth = COLS * CELL_WIDTH;
  canvasHeight = ROWS * CELL_WIDTH;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  canvas.tabIndex = 0;

  clearCanvas();

  targetElement.appendChild(canvas);
}

export function clearCanvas() {
  drawRect({ startX: 0, startY: 0, lengthX: canvasWidth, lengthY: canvasHeight, color: '#000000' });
}

export function drawRect(config: DrawRectConfig) {
  canvasContext.fillStyle = config.color || 'orange';
  canvasContext.fillRect(
    config.startX || 0,
    config.startY || 0,
    config.lengthX || CELL_WIDTH,
    config.lengthY || CELL_WIDTH,
  );
}

export function drawText(config: DrawTextConfig) {
  canvasContext.fillStyle = config.color || '#FFFFFF';
  canvasContext.font = config.font || '64px Raleway';
  canvasContext.textAlign = config.align || 'center';
  canvasContext.textBaseline = config.baseline || 'middle';

  const lineHeight = canvasContext.measureText("M").width;
  String(config.text).split("\n").forEach(line => {
    canvasContext.fillText(line, config.startX, config.startY);
    config.startY += lineHeight;
  });
}

export function renderScene(scene: Scene) {
  clearCanvas();

  drawText({ text: scene.score, startX: canvasWidth / 2, startY: canvasHeight / 2 });

  scene.snake.forEach(({ x, y }, index) => {
    drawRect({ color: !index ? '#AB6200' : '#DC7E00', startX: x * CELL_WIDTH, startY: y * CELL_WIDTH });
  });

  for (const { x, y } of scene.food) {
    drawRect({ color: 'lightgreen', startX: x * CELL_WIDTH, startY: y * CELL_WIDTH });
  }

  const getStateText = () => {
    if (scene.gameEnded) {
      return `GAME OVER! Score: ${scene.score}\n\nPress "space" to restart`;
    }
    if (scene.gamePaused) {
      return `PAUSED\n\nPress "space" to continue`;
    }
    if (scene.gameInit) {
      return `To start the game press "space"`;
    }
  };

  if (scene.gameEnded || scene.gamePaused || scene.gameInit) {
    drawRect({ lengthX: canvasWidth, lengthY: canvasHeight, color: 'rgba(0, 0, 0, 0.75)' });
    drawText({
      text: getStateText(),
      font: '24px Raleway',
      color: '#FFFFFF',
      startX: canvasWidth / 2,
      startY: canvasHeight / 2,
    });
  }
}

export function getRandomPosition(): Point {
  return {
    x: getRandomNumber(1, COLS - 2),
    y: getRandomNumber(1, ROWS - 2),
  };
}

export function getCenterPosition(): Point {
  return {
    x: Math.floor(COLS / 2),
    y: Math.floor(ROWS / 2),
  };
}

export function canvasEscape(head: Point) {
  return (head.x < 0 || head.x >= COLS) || (head.y < 0 || head.y >= ROWS);
}
