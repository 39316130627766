import { fromEvent, interval, empty, of, BehaviorSubject, combineLatest, animationFrameScheduler } from 'rxjs';
import { filter, scan, startWith, map, withLatestFrom, switchMap, tap, share, distinctUntilChanged, skip, shareReplay, buffer } from 'rxjs/operators';

import { renderScene } from './canvas';
import { mapControls, DIRECTIONS, checkOppositeDirection } from './directions';
import { eatFood, generateFood } from './food';
import { moveSnake, SNAKE_LENGTH, generateSnake, bodyHit } from './snake';
import { RegenerateEntities } from './utils';

const MAX_SCORE_KEY = 'maxScore';
const scoreSource$ = new BehaviorSubject(0);

export const score$ = scoreSource$.asObservable().pipe(shareReplay(1));
export const maxScore$ = score$.pipe(
  startWith(parseInt(localStorage.getItem(MAX_SCORE_KEY) || '0', 10)),
  scan((maxScore, currentScore) => currentScore > maxScore ? currentScore : maxScore),
  tap(maxScore => localStorage.setItem(MAX_SCORE_KEY, maxScore.toString())),
);

const snakeLength$ = new BehaviorSubject(SNAKE_LENGTH);
const gameEnded$ = new BehaviorSubject(false);
const regenerateEntities$ = new BehaviorSubject<RegenerateEntities>({ snake: false, food: false });

const gamePaused$ = fromEvent<KeyboardEvent>(document, 'keydown')
  .pipe(
    filter(event => event.key === ' ' || event.code === 'Space'),
    withLatestFrom(gameEnded$),
    switchMap(shouldMoveTheReptile),
    scan(acc => !acc, true),
    share(),
  );

const suicide$ = fromEvent<KeyboardEvent>(document, 'keydown')
  .pipe(
    withLatestFrom(gamePaused$),
    filter(([event, gamePaused]) => !gamePaused && event.key.toUpperCase() === 'K'),
  ).subscribe(killSnake => {
    gameEnded$.next(true);
    new Audio('sounds/death.mp3').play();
  });

const gameTick$ = interval(60)
  .pipe(
    withLatestFrom(gamePaused$, gameEnded$),
    switchMap(([tick, gamePaused, gameEnded]) => gameEnded ? empty() : gamePaused ? empty() : of(tick)),
  );

const direction$ = fromEvent<KeyboardEvent>(document, 'keydown')
  .pipe(
    buffer(gameTick$),
    filter(buffer => !!buffer.length),
    map(keysBuffer => keysBuffer.pop()), // get only last pressed key between ticks
    map(mapControls),
    filter(Boolean),
    startWith(DIRECTIONS.RIGHT),
    scan(checkOppositeDirection),
    distinctUntilChanged(),
  );

const snake$ = gameTick$
  .pipe(
    withLatestFrom(direction$, snakeLength$, regenerateEntities$),
    map(([tick, direction, snakeLength, regEntities]) => ([direction, snakeLength, regEntities])),
    scan(moveSnake, generateSnake()),
    tap(snake => regenerateEntities$.next({ ...regenerateEntities$.value, snake: false })),
    tap(snake => gameEnded$.next(bodyHit(snake))),
    share(),
  );

const food$ = combineLatest(snake$, regenerateEntities$).pipe(
  scan(eatFood, generateFood()),
  distinctUntilChanged(),
  share(),
);

const scene$ = combineLatest(snake$, food$, score$, gameEnded$, gamePaused$).pipe(
    map(([snake, food, score, gameEnded, gamePaused]) => ({ snake, food, score, gameEnded, gamePaused })),
  );

const foodEaten$ = food$.pipe(
  skip(1),
  withLatestFrom(snakeLength$, score$, regenerateEntities$),
)
.subscribe(([food, snakeLength, score, regEntities]) => {
  if (regEntities.food) {
    return regenerateEntities$.next({ ...regEntities, food: false });
  }

  snakeLength$.next(snakeLength + 1);
  scoreSource$.next(score + 10);
  new Audio('sounds/eat.mp3').play();
});

const game$ = interval(1000 / 60, animationFrameScheduler) // Lock to 60FPS
  .pipe(withLatestFrom(scene$, (tick, scene) => scene))
  .subscribe(renderScene);

export const renderInitialScene = () => {
  renderScene({ gameInit: true, snake: [], food: [], gameEnded: false, gamePaused: false, score: '' });
}

function shouldMoveTheReptile([gamePaused, gameEnded]: [KeyboardEvent, boolean]) {
  if (gameEnded) {
    snakeLength$.next(SNAKE_LENGTH);
    scoreSource$.next(0);
    regenerateEntities$.next({ snake: true, food: true });
    gameEnded$.next(false);
    return empty();
  }

  return of(true); // return boolean because of typings, no use at all tho
}
