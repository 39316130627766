import './GameCredits.css';
import React from 'react';

export function setTheme(update: boolean = false) {
  const savedTheme = localStorage.getItem('theme');
  const usedTheme = document.documentElement.getAttribute('data-theme');

  if (!savedTheme) {
    localStorage.setItem('theme', usedTheme);
    return;
  }

  if (savedTheme !== usedTheme) {
    document.documentElement.setAttribute('data-theme', savedTheme);
  }

  if (update) {
    const newTheme = savedTheme === 'light' ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  }
}

const GameCredits = () => {
  return (
    <div className="game-credits" onClick={e => setTheme(true)}>
      <small>💧 Jan Klíma &copy; 2019 </small>
    </div>
  );
};

export default GameCredits;