import './App.css';
import React from 'react';

import GameControls from './GameControls';
import GameStats from './GameStats';
import GameCredits, { setTheme } from './GameCredits';
import SnakePlayground from './SnakePlayground';

interface State {
  score: number;
  maxScore: number;
};

class App extends React.Component {
  state: State;

  constructor(props: any) {
    super(props);
    this.state = { score: 0, maxScore: 0 };
  }

  componentDidMount() {
    setTheme();
  }

  onScoreChanged = (score: number) => {
    this.setState({ score });
  }

  onMaxScoreChanged = (maxScore: number) => {
    this.setState({ maxScore });
  }

  render() {
    return (
      <div className="snake-app">
        <h2>🐍 Reactive Snake</h2>
        <GameControls />
        <GameStats
          score={this.state.score}
          maxScore={this.state.maxScore}
        />
        <SnakePlayground
          onScoreChanged={this.onScoreChanged}
          onMaxScoreChanged={this.onMaxScoreChanged}
        />
        <GameCredits />
      </div>
    );
  }
}

export default App;