export interface Point {
  x: number;
  y: number;
}

export interface DrawTextConfig {
  text: string;
  startX: number;
  startY: number;
  align?: CanvasTextAlign;
  baseline?: CanvasTextBaseline;
  color?: string;
  font?: string;
}

export interface DrawRectConfig {
  startX?: number;
  startY?: number;
  lengthX?: number;
  lengthY?: number;
  color?: string;
}

export interface Scene {
  snake: Point[];
  food: Point[];
  score: any;
  gameEnded: boolean;
  gamePaused: boolean;
  gameInit?: boolean;
}

export interface Directions {
  [key: string]: Point;
}

export interface RegenerateEntities {
  snake: boolean;
  food: boolean;
}

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function checkCollision(a: Point, b: Point) {
  return a.x === b.x && a.y === b.y;
}
