import { getSnakeHead } from './snake';
import { getRandomPosition } from './canvas';
import { Point, checkCollision, RegenerateEntities } from './utils';

export function generateFood() {
  return [
    getRandomPosition(),
    getRandomPosition(),
    getRandomPosition(),
  ];
}

export function eatFood(food: Point[], [snake, regEntities]: [Point[], RegenerateEntities]) {
  if (regEntities.food) {
    food = generateFood();
  }

  const head = getSnakeHead(snake);

  for (let i = 0; i < food.length; i++) {
    if (checkCollision(food[i], head)) {
      food.splice(i, 1);
      return [...food, getRandomPositionInFreeSpace(snake)];
    }
  }

  return food;
}

export function getRandomPositionInFreeSpace(snake: Point[]) {
  let position = getRandomPosition();

  while (snake.some(bodyPart => checkCollision(bodyPart, position))) {
    position = getRandomPosition();
  }

  return position;
}
