import './GameControls.css';
import React from 'react';

const GameControls = () => {
  return (
    <div className="game-controls">
      <div className="col-1">
        <span>
          <strong>Move snake:</strong> arrows or WSAD
        </span>
      </div>
      <a href="https://gitlab.com/DatWolfL/react-snake" target="_blank" rel="noopener noreferrer">📜 Source</a>
    </div>
  );
}

export default GameControls;